<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="9" lg="9">
                รายละเอียดสมาชิก
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-btn class="mx-3" fab dark color="green" @click="Prints">
                  <v-icon dark>fa fa-print</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hbillsale"
          :items="dsbillsale.Array"
          class="elevation-1"
        >
          <template v-slot:body.append>
            <tr>
              <td colspan="4" class="text-right">รวม</td>
              <td class="text-right">{{ dsbillsale.RubberWeightTotal }}</td>
              <td class="text-right">{{ dsbillsale.RubberPercent }}</td>
              <td class="text-right">{{ dsbillsale.RubberWeightDry }}</td>
              <td class="text-right">{{ dsbillsale.BillSalesPriceToday }}</td>
              <td class="text-right">{{ dsbillsale.BillSalesTotal }}</td>
              <td class="text-right">{{ dsbillsale.BillSaleReceive }}</td>
              <td class="text-right">{{ dsbillsale.BillSaleStale }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      User:{},
      dsbillsale: [],
      hbillsale: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "BillSalesDate",
        },
        {
          text: "แปลง",
          align: "left",
          sortable: true,
          value: "Rubber.RubberName",
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "BillSaleRemark",
        },
        {
          text: "น้ำหนัก - ตระกล้า",
          align: "left",
          sortable: true,
          value: "RubberWeight",
        },
        {
          text: "น้ำหนักรวม",
          align: "right",
          sortable: true,
          value: "RubberWeightTotal",
        },
        {
          text: "%",
          align: "right",
          sortable: true,
          value: "RubberPercent",
        },
        {
          text: "ยางแห้ง",
          align: "right",
          sortable: true,
          value: "RubberWeightDry",
        },
        {
          text: "ราคา",
          align: "right",
          sortable: true,
          value: "BillSalesPriceToday",
        },
        {
          text: "รวมเงิน",
          align: "right",
          sortable: true,
          value: "BillSalesTotal",
        },
        {
          text: "จ่าย",
          align: "right",
          sortable: true,
          value: "BillSaleReceive",
        },
        {
          text: "ค้าง",
          align: "right",
          sortable: true,
          value: "BillSaleStale",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let dateStart = this.$route.query.dateStart;
      let dateEnd = this.$route.query.dateEnd;
      let MemberId = this.$route.query.UserId;
      this.Select(MemberId, dateStart, dateEnd);
      this.SelectUser(MemberId);
    });
  },
  methods: {
    SelectUser(v) {
      axios.get("/api/v1/select/SelectId/user?UserId=" + v).then((res) => {
        if (res.status == 200) {
          this.User = res.data;
        }
      });
    },
    Select(MemberId, dateStart, dateEnd) {
      axios
        .post("/api/v1/Select/BillSaleDetail", {
          MemberId: MemberId,
          dateStart: dateStart,
          dateEnd: dateEnd,
        })
        .then((res) => {
          if (res.status == 200) {
            this.dsbillsale = res.data;
          }
        });
    },
    Prints() {
      if (this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext: `รายงานรายละเอียดสมาชิก ชื่อ ${this.User.UserName}`,
          hd: this.hbillsale,
          ite: this.dsbillsale.Array,
          fot: [
            { text: "รวม", cols: 5 },
            { text: this.dsbillsale.RubberWeightTotal, cols: 1 },
            { text: this.dsbillsale.RubberPercent, cols: 1 },
            { text: this.dsbillsale.RubberWeightDry, cols: 1 },
            { text: this.dsbillsale.BillSalesPriceToday, cols: 1 },
            { text: this.dsbillsale.BillSalesTotal, cols: 1 },
            { text: this.dsbillsale.BillSaleReceive, cols: 1 },
            { text: this.dsbillsale.BillSaleStale, cols: 1 },
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
